import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';
import moment from 'moment';

export const reducerName = 'resourceState';

const RESOURCE_GET_ALL_UNITS_REQUEST = 'RESOURCE_GET_ALL_UNITS_REQUEST';
const RESOURCE_GET_ALL_UNITS_RESPONSE = 'RESOURCE_GET_ALL_UNITS_RESPONSE';

const RESOURCE_GET_ALL_BOOKINGS_REQUEST = 'RESOURCE_GET_ALL_BOOKINGS_REQUEST';
const RESOURCE_GET_ALL_BOOKINGS_RESPONSE = 'RESOURCE_GET_ALL_BOOKINGS_RESPONSE';

const RESOURCE_CREATE_BOOKING_REQUEST = 'RESOURCE_CREATE_BOOKING_REQUEST';
const RESOURCE_CREATE_BOOKING_RESPONSE = 'RESOURCE_CREATE_BOOKING_RESPONSE';
const RESOURCE_CREATE_ERROR_DOUBLE_BOOK = 'RESOURCE_CREATE_ERROR_DOUBLE_BOOK';

const RESOURCE_UNIT_OPEN_MENU = 'RESOURCE_UNIT_OPEN_MENU';

const RESOURCE_BOOKING_SHOW_TEAM_MENU = 'RESOURCE_BOOKING_SHOW_TEAM_MENU';
const RESOURCE_BOOKING_SHOW_TIME_MENU = 'RESOURCE_BOOKING_SHOW_TIME_MENU';
const RESOURCE_BOOKING_SET_FIELD = 'RESOURCE_BOOKING_SET_FIELD';

const SET_CATEGORIES_BY_UNITS = 'SET_CATEGORIES_BY_UNITS';
const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
const TOGGLE_SHOW_CATEGORIES = 'TOGGLE_SHOW_CATEGORIES';
const List_ACTIVE_CATEGORY_UNITS = 'List_ACTIVE_CATEGORY_UNITS';

const initialState = {
    units: [],
    allUnits: [],
    bookings: [],
    createBooking: {
        unitId: '',
        teamId: '',
        personId: null,
        startTime: moment(),
        endTime: moment()
    },
    doubleBook: false,
    unitMenuId: false,
    showTeamMenu: false,
    showTimeMenu: false,
    loading: {getUnits: false, getBookings: false},

    activeCategory: undefined,
    showCategories: true,
    categories: [],
};

export const actionCreators = {
    setBookingField: (field, value) => async(dispatch, getState) => {
        dispatch({type: RESOURCE_BOOKING_SET_FIELD, field, value});
    },
    setTeamAndBook: (teamId, personId) => async(dispatch, getState) => {
        const cb = {
            ...getState()[reducerName].createBooking,
            teamId: teamId,
            personId: personId
        };
        dispatch(actionCreators.createBooking(cb.unitId, cb.teamId, cb.personId, cb.startTime, cb.endTime));
    },
    unitMenu: (unitId, visible) => async(dispatch, getState) => {
        dispatch({type: RESOURCE_UNIT_OPEN_MENU, unitId, visible});
    },
    showTeamMenu: (show) => async(dispatch, getState) => {
        dispatch({type: RESOURCE_BOOKING_SHOW_TEAM_MENU, show});
    },
    showTimeMenu: (show) => async(dispatch, getState) => {
        dispatch({type: RESOURCE_BOOKING_SHOW_TIME_MENU, show});
    },
    createBooking: (unitId, teamId, personId, startTime, endTime) => async(dispatch, getState) => {
        dispatch({type: RESOURCE_CREATE_BOOKING_REQUEST});

        const url = `${config.server}/api/unitbooking`;
        axios({
            method: 'POST',
            data: {unitId, teamId, personId, startTime: moment(startTime).format('YYYY-MM-DD HH:mm'), endTime: moment(endTime).format('YYYY-MM-DD HH:mm')},
            url: url,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: RESOURCE_CREATE_BOOKING_RESPONSE, data: response.data});
        }).catch(error => {
            console.log(`ERROR(axios): ${RESOURCE_CREATE_BOOKING_REQUEST}`);
            if(error.response.data === 'double_booking'){
                dispatch({type: RESOURCE_CREATE_ERROR_DOUBLE_BOOK});
            }
        });
    },
    getAllUnits: () => async(dispatch, getState) => {
        dispatch({type: RESOURCE_GET_ALL_UNITS_REQUEST});

        const url = `${config.server}/api/unit`;
        axios({
            method: 'GET',
            url: url,
            withCredentials: true,
            auth: createAuth(getState),
        }).then(response => {
                dispatch({type: RESOURCE_GET_ALL_UNITS_RESPONSE, data: response.data});
                dispatch({type: SET_CATEGORIES_BY_UNITS})
        }).catch(error => {
            //nothing
        });
    },
    //all = 6month before now and 6month after
    getAllBookings: () => async(dispatch, getState) => {
        dispatch({type: RESOURCE_GET_ALL_BOOKINGS_REQUEST});

        const url = `${config.server}/api/unitbooking`;
        axios({
            method: 'GET',
            url: url,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: RESOURCE_GET_ALL_BOOKINGS_RESPONSE, data: response.data});
        }).catch(error => {

        })
    },
    setToCategoryView: () => async(dispatch, getState) => {
        dispatch({type: TOGGLE_SHOW_CATEGORIES, data: true});
        dispatch({type: SET_ACTIVE_CATEGORY, data: undefined});
    },
    setToUnitView: (category) => async(dispatch, getState) => {
        dispatch({type: TOGGLE_SHOW_CATEGORIES, data: false});
        dispatch({type: SET_ACTIVE_CATEGORY, data: category});
        dispatch({type: List_ACTIVE_CATEGORY_UNITS, data: category});
    },
};

const reducerMethods = {
    RESOURCE_BOOKING_SET_FIELD: (state, action) => {
        return{
            ...state,
            createBooking:{
                ...state.createBooking,
                [action.field]: action.value
            }
        }
    },
    RESOURCE_BOOKING_SHOW_TEAM_MENU: (state, action) => {
        return{
            ...state,
            showTeamMenu: action.show
        }
    },
    RESOURCE_BOOKING_SHOW_TIME_MENU: (state, action) => {
        return{
            ...state,
            showTimeMenu: action.show,
            doubleBook: false
        }
    },
    RESOURCE_UNIT_OPEN_MENU: (state, action) => {
        return{
            ...state,
            unitMenuId: action.visible ? action.unitId : false
        }
    },
    RESOURCE_GET_ALL_UNITS_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getUnits: true
            }
        }
    },
    RESOURCE_GET_ALL_UNITS_RESPONSE: (state, action) => {
        return{
            ...state,
            units: action.data,
            allUnits: action.data,
            loading:{
                ...state.loading,
                getUnits: false
            }
        }
    },
    RESOURCE_GET_ALL_BOOKINGS_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getBookings: true
            }
        }
    },
    RESOURCE_GET_ALL_BOOKINGS_RESPONSE: (state, action) => {
        return{
            ...state,
            bookings: action.data,
            loading:{
                ...state.loading,
                getBookings: false
            }
        }
    },
    RESOURCE_CREATE_BOOKING_REQUEST: (state, action) => {
        return{
            ...state,
            doubleBook: false
        }
    },
    RESOURCE_CREATE_ERROR_DOUBLE_BOOK: (state, action) => {
        return{
            ...state,
            doubleBook: true,
            showTeamMenu: false,
            showTimeMenu: true
        }
    },
    RESOURCE_CREATE_BOOKING_RESPONSE: (state, action) => {
        return{
            ...state,
            showTeamMenu: false,
            unitMenuId: false,
            bookings:[
                ...state.bookings,
                action.data
            ]
        }
    },
    SET_CATEGORIES_BY_UNITS: (state, action) => {
        return {
            ...state,
            categories: [...new Set(state.units.map(u => u.category || "Andre"))],
        }
    },
    SET_ACTIVE_CATEGORY: (state, action) => {
        return {
            ...state,
            activeCategory: action.data,
        }
    },
    TOGGLE_SHOW_CATEGORIES: (state, action) => {
        if (action.data)
        {
            return {
                ...state,
                showCategories: action.data,
                units: state.allUnits
            }
        }
        else {
            return {
                ...state,
                showCategories: action.data,
            }
        }
        
    },
    List_ACTIVE_CATEGORY_UNITS: (state, action) => {
        return {
            ...state,
            allUnits: state.units,
            units: state.units.filter(u => u.category === state.activeCategory || state.activeCategory === "Andre" && u.category === null)
        }
    }
};


export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};