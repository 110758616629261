import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };

    const teamGroenDrift = teams.find(x => x.id === 62);
    const teamGroenDrift2 = teams.find(x => x.id === 120);
    const subteamDistrikt = teams.find(x => x.id === 58);
    const subteamMidtby = teams.find(x => x.id === 135);
    const subteamVest = teams.find(x => x.id === 134);
    const subteamDriftGroen = teams.find(x => x.id === 55);

    const teamDriftVejOverTeam = teams.find(x => x.id === 72); // TODO ret til det rette id
    const teamDriftVej = teams.find(x => x.id === 59);

    //const teamDriftIntern = teams.find(x => x.id === 61);
    const teamVaerksted = teams.find(x => x.id === 65);

    const teamDriftKirkegaard = teams.find(x => x.id === 60);
    const subteamSorgenfriKirkegaard = teams.find(x => x.id === 63);
    const subteamLyngbyParkKirkegaard = teams.find(x => x.id === 64);

    const arealDriftChef = teams.find(x => x.id === 67);
    const teamAdministration = teams.find(x => x.id === 53);


    // Vi har umiddelbart ikke brug for at tilføje units her - men hvis vi får brug for det, skal det gøres på hvert team
    //addUnits(administration);
    //addUnits(pladsen);

    return (
        <div className="team-list">
            {teamGroenDrift &&
                <Team
                    team={teamGroenDrift}
                    width={41}
                    height={98}
                    // subTeams={[subteamOest, subteamMidtby, subteamVest]}
                    subTeams={[subteamDistrikt]}
                    className={"drift-1"}
                />
            }
            {teamGroenDrift2 &&
                <Team
                    team={teamGroenDrift2}
                    width={41}
                    height={98}
                    subTeams={[subteamDriftGroen]}
                    className={"drift-2"}
                />
            }
            {teamDriftKirkegaard &&
                <Team
                    team={teamDriftKirkegaard}
                    width={21}
                    height={98}
                    subTeams={[subteamSorgenfriKirkegaard, subteamLyngbyParkKirkegaard]}
                />
            }
            {teamDriftVejOverTeam &&
                <Team
                    team={teamDriftVejOverTeam}
                    width={20}
                    height={74}
                    subTeams={[teamDriftVej]}
                    innerHeight={99}
                />
            }
            
            {teamAdministration &&
                <Team
                    team={teamAdministration}
                    width={18}
                    height={74}
                    innerHeight={99}
                />
            }
            {teamVaerksted &&
                <Team
                    team={teamVaerksted}
                    width={20}
                    height={24}
                    bottomMargin={0.1}
                />
            }
            {arealDriftChef && 
                <Team
                    team={arealDriftChef}
                    width={18}
                    height={24}
                    bottomMargin={0.1}
                    
                />
            }
         
            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(CustomTeamList);